var menuPNRConfiguration = [
  {
    name: "Général",
    visible: true,
    menu: [
      {
        name: "",
        menu: [
          // {
          //     "name": "Accueil",
          //     "rapportBI": {
          //         "id": "9a6196d3-22f9-4f22-85d7-86d5e97f73dc",
          //         "embedURL": "https://app.powerbi.com/reportEmbed?reportId=9a6196d3-22f9-4f22-85d7-86d5e97f73dc&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
          //         "page": "ReportSection4e8bfa0fd160c44ecda0",
          //     }
          // },
          {
            name: "Contexte",
            rapportBI: {
              id: "d3e0a83a-2251-4b62-953e-fa7dcc741596",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=d3e0a83a-2251-4b62-953e-fa7dcc741596&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Données Agricoles",
    visible: true,
    menu: [
      {
        name: "",
        menu: [
          {
            name: "Surfaces Agricoles",
            rapportBI: {
              id: "5758bbda-58fa-4b50-a1bb-18c68d7a67cb",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=5758bbda-58fa-4b50-a1bb-18c68d7a67cb&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection49ec70125f25efe54d99",
            },
          },
          {
            name: "Cheptel",
            rapportBI: {
              id: "4b71e3cb-4827-48d6-b4ec-5d374353141d",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=4b71e3cb-4827-48d6-b4ec-5d374353141d&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection14e7d09c616045683293",
            },
          },
          {
            name: "Exploitations Agricoles",
            rapportBI: {
              id: "4b71e3cb-4827-48d6-b4ec-5d374353141d",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=4b71e3cb-4827-48d6-b4ec-5d374353141d&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSectionf65ee6df4d01568201ed",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Agroécologie",
    icon: "fa-tractor",
    visible: true,
    menu: [
      {
        name: "Indicateurs d'Etat",
        menu: [
          {
            name: "Paysages et IAE",
            rapportBI: {
              id: "2b4201dc-badc-4726-b4dc-eb35aceafbce",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=2b4201dc-badc-4726-b4dc-eb35aceafbce&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection4fe930d8a549be7f9614",
            },
          },
          {
            name: "Qualité de l'eau",
            rapportBI: {
              id: "2b4201dc-badc-4726-b4dc-eb35aceafbce",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=2b4201dc-badc-4726-b4dc-eb35aceafbce&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection57054b6ce976c0a253d4",
            },
          },
          {
            name: "Pratiques Agroécologiques",
            rapportBI: {
              id: "a24b5b22-b7fc-4b70-98bf-0a65ae5585c1",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=a24b5b22-b7fc-4b70-98bf-0a65ae5585c1&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection4381dddaa80a57f0f8a8",
            },
          },
        ],
      },
      {
        name: "Indicateurs de pression",
        menu: [
          {
            name: "Artificialisation",
            rapportBI: {
              id: "2b4201dc-badc-4726-b4dc-eb35aceafbce",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=2b4201dc-badc-4726-b4dc-eb35aceafbce&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection16570ae548cacdd5554d",
            },
          },
          {
            name: "Usage des pesticides",
            rapportBI: {
              id: "2b4201dc-badc-4726-b4dc-eb35aceafbce",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=2b4201dc-badc-4726-b4dc-eb35aceafbce&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection2809d505effe72d5571e",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Alimentation",
    visible: true,
    menu: [
      {
        name: "Opérateurs économiques",
        menu: [
          {
            name: "Milieu de Chaîne",
            rapportBI: {
              id: "b16b973e-3187-4fa5-b06f-2bf58b145bf6",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=b16b973e-3187-4fa5-b06f-2bf58b145bf6&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSectionc8f4dc128477c4c85e4e",
            },
          },
          {
            name: "Distribution",
            rapportBI: {
              id: "e06eddbc-76f9-4707-a15c-1ba27f369497",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=e06eddbc-76f9-4707-a15c-1ba27f369497&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection1a210ca7d6fd9cb1df78",
            },
          },
          {
            name: "Restauration",
            rapportBI: {
              id: "e06eddbc-76f9-4707-a15c-1ba27f369497",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=e06eddbc-76f9-4707-a15c-1ba27f369497&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection1e79f6cef8a3c0810477",
            },
          },
        ],
      },
      {
        name: "Valorisation",
        visible: true,
        menu: [
          {
            name: "Valorisation",
            rapportBI: {
              id: "09889213-ef6b-4da9-ab0c-78fa930e54f4",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=09889213-ef6b-4da9-ab0c-78fa930e54f4&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection4381dddaa80a57f0f8a8",
            },
          },
        ],
      },
      {
        name: "Potentiels",
        menu: [
          {
            name: "Potentiel Nourricier",
            rapportBI: {
              id: "efb9bca3-545f-4e82-bf6d-8f675645773c",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=efb9bca3-545f-4e82-bf6d-8f675645773c&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSectionf6458db921620002cba0",
            },
          },
          {
            name: "Potentiel Agro-industriel",
            rapportBI: {
              id: "efb9bca3-545f-4e82-bf6d-8f675645773c",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=efb9bca3-545f-4e82-bf6d-8f675645773c&groupId=a22af287-49ed-4c46-b59d-e4d964f3ba06&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
              page: "ReportSection3cdb1e7a75e59d379822",
            },
          },
        ],
      },
    ],
  },
];

const slicer_principal_title = "Choix du territoire";
const slicer_comparison_title = "Territoire de comparaison";
const slicer_comparison_title_pnr = "Territoire de comparaison PNR";
const slicer_communes = "Choix de vos communes";
const slicer_aires_parc_pnx = "Aires du Parc";

export default {
  menuPNRConfiguration,
  slicer_principal_title,
  slicer_comparison_title,
  slicer_comparison_title_pnr,
  slicer_communes,
  slicer_aires_parc_pnx,
};
