<template>
  <div :class="{ 'plein-ecran': estPleinEcran }">
    <div id="bouton-plein-ecran" @click="estPleinEcran = !estPleinEcran">
      <span v-if="!estPleinEcran">passer en plein écran</span>
      <span v-if="estPleinEcran">quitter le mode plein écran</span>
    </div>

    <div class="colonnes" id="en-tete">
      <div class="colonne" id="titre">
        <h1><b>O</b>bservatoire des <b>P</b>arcs</h1>
      </div>
      <DefaultHeader></DefaultHeader>
    </div>

    <div class="colonnes">
      <div class="colonne" id="colonne-navigation">
        <ul id="navigation">
          <li
            v-for="menu_item in menu"
            v-bind:key="menu_item.name"
            :class="{
              niveau1: true,
              visible: menu_item.visible,
            }"
          >
            <span
              class="element_de_liste"
              @click="menu_item.visible = !menu_item.visible"
            >
              {{ menu_item.name }}
              <span class="fleche">⪢</span>
            </span>

            <ul class="liste-niveau-2">
              <li
                v-for="menu_item2 in menu_item.menu"
                v-bind:key="menu_item2.name"
                class="niveau2"
              >
                <a
                  v-if="'rapportBI' in menu_item2"
                  v-bind:class="{
                    element_de_liste: true,
                    niveau2: true,
                    selected:
                      selected_report ==
                      menu_item2.rapportBI.id + menu_item2.rapportBI.page,
                  }"
                  @click="tab_clicked(menu_item2)"
                >
                  {{ menu_item2.name }}
                </a>
                <p
                  v-if="!('rapportBI' in menu_item2) && menu_item2.name != ''"
                  class="element_de_liste"
                >
                  {{ menu_item2.name }}
                  <span v-if="'explication' in menu_item2" class="explication">
                    ?
                    <span class="infobulle">{{ menu_item2.explication }}</span>
                  </span>
                </p>
                <ul>
                  <li
                    v-for="menu_item3 in menu_item2.menu"
                    v-bind:key="menu_item3.name"
                    @click="tab_clicked(menu_item3)"
                    class="niveau3"
                  >
                    <a
                      v-if="'rapportBI' in menu_item3"
                      v-bind:class="{
                        element_de_liste: true,
                        niveau3: true,
                        selected:
                          selected_report ==
                          menu_item3.rapportBI.id + menu_item3.rapportBI.page,
                      }"
                    >
                      {{ menu_item3.name }}
                    </a>
                    <p v-else class="element_de_liste">{{ menu_item3.name }}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="colonne" id="colonne-contenu">
        <div class="colonnes" id="choix-des-territoires">
          <div class="colonne filtre" id="territoire-principal">
            <h2>Choix du Parc</h2>
            <!-- <Multiselect
              v-model="territoires_selected"
              placeholder="Choisissez votre parc"
              :searchable="true"
              :loading="isLoading"
              :options="optionTerritoiresDefaut"
              mode="tags"
              ref="selecteur_principal"
            ></Multiselect> -->
            <Multiselect
              v-model="listePNIdChoisis"
              placeholder="Choisissez votre parc"
              mode="tags"
              :searchable="true"
              :close-on-select="false"
              :options="optionTerritoiresDefaut"
              ref="selecteur_principal2"
            ></Multiselect>
          </div>
          <div class="colonne filtre" id="choix-villes">
            <h3>Choix des communes</h3>
            <!-- <Multiselect
              v-model="villes_multiselect"
              placeholder="Les communes du parc"
              :searchable="true"
              :loading="isLoading"
              :options="pnr_communes_selection"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              mode="multiple"
              ref="selecteur_communes"
            >
              <template v-slot:selection="{ values, isOpen }">
                ><span
                  class="multiselect__single"
                  v-if="values.length"
                  v-show="!isOpen"
                  >{{ values.length }} options selected</span
                >
              </template></Multiselect
            > -->
            <Multiselect
              v-model="listeCommuneNom"
              placeholder="Les communes du parc"
              mode="multiple"
              :searchable="true"
              :close-on-select="false"
              :groups="true"
              :options="CommunesSelectionnees"
              :hide-selected="false"
            />
          </div>
          <div class="colonne filtre" id="territoire-comparaison">
            <h3>Choix du territoire de comparaison</h3>
            <Multiselect
              v-model="comparison_selected"
              placeholder="Choisissez le territoire de comparaison"
              :searchable="true"
              :loading="isLoading"
              :options="RegionsListe"
              ref="selecteur_comparaison"
            >
            </Multiselect>
          </div>
        </div>
        <div class="colonne" v-if="listePNIdChoisis.length == 1">
          <span v-if="listePNIdChoisis[0].slice(0, 3) === 'pnx'">
            <h3>Quelle partie du parc</h3>
            <Multiselect
              v-model="aires_du_parc_PNX_selected"
              :options="['Tout le Parc', 'Coeur de Parc', 'Aire d\'adhésion']"
            />
          </span>
        </div>
        <blocAccordeonVue :key="pnr_communes_selection">
          <template #titre>
            <h3>Communes sélectionnées</h3>
          </template>
          <template #contenu>
            <ul>
              <li v-for="item in listeCommuneNom" :key="item">
                {{ item }}
              </li>
            </ul>
          </template>
        </blocAccordeonVue>
        <div>
          <div v-if="loading_step != null">
            <p>{{ loading_step }}</p>
            <CoucheChargement></CoucheChargement>
          </div>
          <PowerbiContainer
            v-if="rapportBISelected != null"
            :rapportBI="rapportBISelected"
            :territoires_principaux="listePNNomChoisis"
            :territoires_de_comparaison_pnr="territoires_de_comparaison_pnr"
            :villes_selected="listeCommuneIds"
            :powerbi_report_list="powerbi_report_list"
            :aires_du_parc_PNX_selected="aires_du_parc_PNX_selected"
          >
          </PowerbiContainer>
        </div>
        >
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import basic_api from "@/repositories/basic_api.js";
import PNRMenuConfig from "@/components/PNR/PNR_menu_configuration.js";
import menu_utils from "@/utils/menu_utils.js";
import DefaultHeader from "@/components/headers/DefaultHeader.vue";
import PowerbiContainer from "@/components/widgets/PowerbiContainer.vue";
import CoucheChargement from "@/components/headers/CoucheChargement.vue";
import pnr_communes from "@/assets/donnees/PNR/pnr_communes.json";
import blocAccordeonVue from "../bas-niveau/blocAccordeon.vue";
import {
  creerObjetFiltre,
  getCommunesIds,
  getToutesCommunesParcs,
  listeParcsUltraMarins,
} from "@/components/PNR/utils/getCommunes.js";

import {
  creerObjetPNFiltre,
  getPNNoms,
  getPNIds,
} from "@/components/PNR/utils/getPN";

export default {
  name: "TerritoiresGeneriques",
  components: {
    Multiselect,
    DefaultHeader,
    PowerbiContainer,
    CoucheChargement,
    blocAccordeonVue,
  },
  data() {
    return {
      selected_report: "",
      menu: {},
      PNRNames: null,
      loading_step: null,
      territoires_selected: [],
      aires_du_parc_PNX_selected: "Tout le Parc",
      choix_territoires: null,
      estPleinEcran: false,
      displayed_project: "",
      rapportBISelected: null,
      comparison_selected: "France métropolitaine",
      territoires_de_comparaison_pnr: [],
      territoires_principaux: [],
      isLoading: true,
      optionTerritoiresDefaut: null,
      pnr_communes_selection: [],
      pnr_communes: pnr_communes,
      RegionsListe: [
        { value: "Auvergne-Rhône-Alpes", label: "Auvergne-Rhône-Alpes" },
        { value: "Bourgogne-Franche-Comté", label: "Bourgogne-Franche-Comté" },
        { value: "Bretagne", label: "Bretagne" },
        { value: "Centre-Val-de-Loire", label: "Centre-Val-de-Loire" },
        { value: "Corse", label: "Corse" },
        { value: "Grand Est", label: "Grand Est" },
        { value: "Hauts-de-France", label: "Hauts-de-France" },
        { value: "Île-de-France", label: "Île-de-France" },
        { value: "Normandie", label: "Normandie" },
        { value: "Nouvelle-Aquitaine", label: "Nouvelle-Aquitaine" },
        { value: "Occitanie", label: "Occitanie" },
        { value: "Pays-de-la-Loire", label: "Pays-de-la-Loire" },
        {
          value: "Provence-Alpes-Côte-d'Azur",
          label: "Provence-Alpes-Côte-d'Azur",
        },
        { value: "France métropolitaine", label: "France métropolitaine" },
      ],
      listePNNoms: [],
      listePNNomChoisis: [],
      listePNIdChoisis: [],
      CommunesSelectionnees: null,
      listeCommuneNom: null,
      listeCommuneIds: null,
    };
  },
  watch: {
    listePNIdChoisis: function (newValue) {
      if (newValue.includes("pnFEDERATION")) {
        this.listePNNomChoisis = this.optionTerritoiresDefaut.map(
          (item) => item.label
        );
      } else if (newValue.includes("pnrFEDERATION")) {
        this.listePNNomChoisis = this.optionTerritoiresDefaut
          .filter((parc) => parc.value.includes("pnr"))
          .map((item) => item.label);
      } else if (newValue.includes("pnxFEDERATION")) {
        this.listePNNomChoisis = this.optionTerritoiresDefaut
          .filter((parc) => parc.value.includes("pnx"))
          .map((item) => item.label);
      } else {
        this.listePNNomChoisis = getPNNoms(
          newValue,
          this.optionTerritoiresDefaut
        );
      }
      let listePNReconstruite = getPNIds(
        this.listePNNomChoisis,
        this.optionTerritoiresDefaut
      );

      this.listeCommuneIds = getToutesCommunesParcs(
        listePNReconstruite,
        this.pnr_communes
      );
      this.CommunesSelectionnees = creerObjetFiltre(
        listePNReconstruite,
        this.pnr_communes
      );
      // gestion des parties du parc
      if (listePNReconstruite.length > 1) {
        this.aires_du_parc_PNX_selected = "Tout le Parc";
      } else if (listePNReconstruite.length === 1) {
        if (newValue[0].slice(0, 3) === "pnr") {
          this.aires_du_parc_PNX_selected = "Tout le Parc";
        } else {
          this.aires_du_parc_PNX_selected = "Tout le Parc";
        }
      }
    },
    listeCommuneNom: function (valeur) {
      if (valeur.length > 0) {
        this.listeCommuneIds = getCommunesIds(valeur, pnr_communes);
      } else {
        this.listeCommuneIds = getToutesCommunesParcs(
          this.listePNIdChoisis,
          pnr_communes
        );
      }
    },
    comparison_selected: async function (newValue, oldValue) {
      console.log(oldValue, "=>", newValue);
      if (newValue == "France métropolitaine") {
        this.territoires_de_comparaison_pnr = this.RegionsListe.map(
          (item) => item.value
        );
      } else {
        this.territoires_de_comparaison_pnr = [newValue];
        console.log(
          "territoires_de_comparaison_pnr",
          this.territoires_de_comparaison_pnr
        );
      }
    },
  },
  beforeMount: async function () {
    this.menu = PNRMenuConfig.menuPNRConfiguration;
    basic_api.searchMatchingTerritoires("P", "pnr").then((response) => {
      const response_sorted = response.sort((a, b) => {
        return a.localeName.localeCompare(b.localeName);
      });
      this.PNRNames = new Map(
        response_sorted
          .map((item) => {
            return [item.code_territoire, item.localeName];
          })
          .sort()
      );
      var ensemble_parcs = [
        {
          label: "Ensemble des Parcs",
          value: "pnFEDERATION",
        },
        {
          label: "Ensemble des 56 PNR",
          value: "pnrFEDERATION",
        },
        {
          label: "Ensemble des 8 Parcs nationaux",
          value: "pnxFEDERATION",
        },
      ];
      this.optionTerritoiresDefaut = ensemble_parcs.concat(
        response.map((item) => {
          return { value: item.code_territoire, label: item.localeName };
        })
      );
      this.isLoading = false;
      // définition du territoire principal sélectionné par défaut
      basic_api.searchMatchingTerritoires("P", "pnx").then((response) => {
        this.optionTerritoiresDefaut = this.optionTerritoiresDefaut.concat(
          response.map((item) => {
            return { value: item.code_territoire, label: item.localeName };
          })
        );
        this.optionTerritoiresDefaut = this.optionTerritoiresDefaut.filter(
          (parc) => !listeParcsUltraMarins.includes(parc.label)
        );

        this.listePNNoms = creerObjetPNFiltre(this.optionTerritoiresDefaut);
        const response_sorted = response.sort((a, b) => {
          return a.localeName.localeCompare(b.localeName);
        });
        let PNXNames = new Map(
          response_sorted.map((item) => {
            return [item.code_territoire, item.localeName];
          })
        );
        this.PNRNames = new Map([...this.PNRNames, ...PNXNames]);
        const code_du_territoire =
          this.$route.query.code_du_territoire ?? "pnrFR8000002";
        this.listePNIdChoisis = [code_du_territoire];
        this.$refs.selecteur_comparaison.select(
          this.RegionsListe.find(
            (item) => item.value === "France métropolitaine"
          )
        );
        this.territoires_de_comparaison_pnr = this.RegionsListe.map(
          (item) => item.value
        );
        console.log(
          "territoires_de_comparaison_pnr",
          this.territoires_de_comparaison_pnr
        );
      });
    });
  },
  mounted: function () {
    this.refresh_token();
    this.CommunesSelectionnees = creerObjetFiltre(
      this.listePNIdChoisis,
      this.pnr_communes
    );
  },
  computed: {
    powerbi_report_list: function () {
      var powerbi_list = {};
      for (var menu_item of this.menu) {
        this.list_report_from_menu_item(menu_item, powerbi_list);
      }
      return powerbi_list;
    },
    pnr_liste_communes: function () {
      return this.pnr_communes;
    },
  },
  methods: {
    compute_selector_label: function (values_selected, selector) {
      console.log(values_selected, selector);
      return values_selected
        .map((item) => {
          return item.label;
        })
        .join(", ");
    },
    list_report_from_menu_item: function (menu_item, powerbi_list) {
      if ("rapportBI" in menu_item) {
        powerbi_list[menu_item.rapportBI.id + menu_item.rapportBI.page] =
          menu_item.rapportBI;
      }
      if ("menu" in menu_item) {
        for (var sub_menu_item of menu_item["menu"]) {
          this.list_report_from_menu_item(sub_menu_item, powerbi_list);
        }
      }
    },
    refresh_token: async function () {
      this.loading_step = "Connexion à Powerbi";
      var success = await basic_api.generateToken();
      console.log("get token result :", success);
      if (success) {
        this.loading_step = null;
        var page_defined_in_url = this.$route.query.page;
        if (page_defined_in_url) {
          var default_item =
            menu_utils.get_menu_item_from_name(page_defined_in_url);
        }
        if (default_item == null) {
          default_item = this.menu[0].menu[0].menu[0];
        }
        this.selected_report =
          default_item.rapportBI.id + default_item.rapportBI.page;
        this.rapportBISelected = default_item.rapportBI;
      } else {
        this.loading_step = "Failed to connect to PowerBI";
      }
    },
    tab_clicked: async function (menu_item) {
      console.log("tab clicked called with", menu_item);
      console.log("selected_report", this.selected_report);
      this.selected_report = menu_item.rapportBI.id + menu_item.rapportBI.page;
      if ("rapportBI" in menu_item) {
        // Update URL with current menu name
        var newRoute = {
          path: this.$route.path,
          query: { ...this.$route.query },
        };
        newRoute.query.page = menu_item.name;
        this.$router.push(newRoute);
        this.rapportBISelected = menu_item.rapportBI;
      } else {
        this.tab_clicked(menu_item.menu[0]);
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  margin: 1rem;
  max-width: 414px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}
#en-tete {
}
#titre {
  width: 430px;
  flex: none;
}
#logos {
  text-align: right;
  width: calc(100% - 430px);
  padding: 5px;
  height: 100px;
}
.logo {
  /* float: right; */
  height: 100px;
  /* width: 300px; */
  /* display: inline; */
  padding-left: 2rem;
  vertical-align: middle;
}
.logo-petit {
  height: 80%;
}
.colonnes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.colonne {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

#colonne-navigation {
  background-color: rgb(248 248 248);
  flex: 1;
  height: calc(100vh - 122px);
  overflow: auto;
}
#colonne-contenu {
  flex: 4;
}
#navigation {
  padding: 1rem 0;
  text-align: left;
}
.liste-niveau-2 {
  max-height: 0px;
  transition: 0.3s;
  overflow: hidden;
}
.visible .liste-niveau-2 {
  max-height: 800px;
}
.element_de_liste {
  padding: 3px 1rem;
  width: 100%;
  display: inline-block;
  position: relative;
}
.fleche {
  position: absolute;
  left: calc(100% - 2rem);
  top: 3px;
  visibility: hidden;
  transition: 0.3s;
}
a.element_de_liste {
  color: inherit;
}
a.element_de_liste:not(.selected):hover {
  background-color: rgb(225, 225, 225);
}
.niveau1 {
  margin-bottom: 2rem;
}
.niveau1 > .element_de_liste {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.niveau1 > .element_de_liste:hover .fleche {
  visibility: visible;
}
.visible .fleche {
  visibility: visible;
  transform: rotate(90deg) translate(2px, 0px);
}

.niveau2 > .element_de_liste {
  /* font-size: 20px; */
  font-weight: 600;
  /* opacity: 0.6; */
  color: #636363;
  text-transform: uppercase;
}
.niveau2 {
  margin-bottom: 1rem;
}
.niveau3 {
  /* padding-left: 0.5rem; */
}
.selected {
  background-color: rgb(59, 59, 59);
  color: white !important;
}
.explication {
  border-radius: 20px;
  height: 22px;
  width: 22px;
  border: 2px solid #bebebe;
  position: relative;
  /* z-index: -2; */
  display: inline-block;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  color: #bebebe;
  top: -2px;
  /* border-style: inset; */
  box-shadow: inset 0 0 0px #000;
  margin-left: 6px;
  cursor: default;
}
.infobulle {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5px 8px;
  text-align: left;
  text-transform: none;
  font-weight: normal;
  border-radius: 0 6px 6px 6px;
  /* left: calc(100% + 15px); */
  top: 25px;
  color: white;
}
.explication:hover .infobulle {
  visibility: visible;
}

.filtre {
  height: 100%;
}

#choix-des-territoires {
  padding: 1rem;
  gap: 16px;
}
#territoire-principal {
  flex-grow: 1;
}
#territoire-principal .v-select {
  max-width: 400px;
}

#choix-villes {
  justify-content: flex-end;
  height: 100%;
}
#territoire-comparaison {
  padding: 0;
  flex-grow: 1;
  justify-content: flex-end;
}
.chargement {
  position: absolute;
}
.v-select {
  max-height: 2.2rem;
}
#bouton-plein-ecran {
  position: absolute;
  top: 213px;
  right: 18px;
  background-color: white;
  text-decoration: underline;
  /* color: blue; */
  padding: 3px;
  cursor: pointer;
  user-select: none;
  z-index: 1;
}
.plein-ecran #bouton-plein-ecran {
  top: 0;
}

/* Icone de chargement */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
